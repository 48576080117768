<template>
  <div>
    <v-list-item>
      <v-list-item-content v-if="!hideContent">
        <v-list-item-title class="nowrap">
          <slot name="title">{{title}}</slot>
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="subtitle"
          v-text="subtitle"
        />
        <v-list-item-subtitle
          v-if="thirdtitle"
          v-text="thirdtitle"
        />
      </v-list-item-content>
      <slot name="actions">
        <v-list-item-action v-if="(actions && actions.length>=1)">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <div
                v-for="(action,idxa) in actions"
                :key="idxa"
              >
                <v-list-item @click="$emit('action',action.value)">
                  <v-list-item-title v-text="action.text" />
                </v-list-item>
                <v-divider v-if="(idxa+1 < actions.length)" />
              </div>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </slot>
    </v-list-item>
    <v-divider v-if="divider" />
  </div>
</template>
<script>
export default {
  name: "ListItem",
  props: {
    title: String,
    subtitle: String,
    thirdtitle: String,
    actions: Array,
    divider: Boolean,
    hideContent: Boolean,
  },
};
</script>