<template>
  <div>
    <v-subheader
      v-if="title"
      v-text="title"
    />
    <v-file-input
      :label="label"
      filled
      v-model="file"
      clearable
      :loading="uploading"
      :disabled="uploading"
      :prepend-icon="''"
      :prepend-inner-icon="icon ? icon :'attach_file'"
      :hint="hint"
      :persistent-hint="hint?true:false"
    />
    <v-btn
      class="mb24"
      color="primary"
      :disabled="uploading || !file"
      :loading="uploading"
      @click="_uploadFile"
    >
      <v-icon>upload</v-icon>Cargar
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "ComprasFileUpload",
  props: {
    title: String,
    label: String,
    icon: String,
    path: String,
    hint: String,
  },
  data() {
    return {
      file: null,
      uploading: false,
    };
  },
  methods: {
    async _uploadFile() {
      this.uploading = true;
      try {
        var formData = new FormData();
        formData.append("file", this.file, this.file.name);
        formData.append("path", this.path ? this.path : "");
        const { status, body } = await this.$http.post(
          "files",
          formData,
          this.$headers
        );
        if (status === 201 && "file" in body) {
          this.$emit("file", body.file);
          this.file = null;
        } else {
          this.$emit("httpError", res);
        }
        this.uploading = false;
      } catch (err) {
        this.uploading = false;
        this.cargando = false;
        this.$emit("httpError", err);
      }
    },
  },
};
</script>