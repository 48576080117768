<template>
  <section class="ProveedorForm empresas__inner container mt24 mb24">
    <ButtonBackHome
      to="/empresas/"
      text="Regresar al inicio"
    />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Tu información como proveedor</h2>
      <div class="empresas_home__text">Aquí podrás consultar y actualizar tu información como proveedor. Recuerda es importante tener al día la documentación fiscal solicitada.</div>
    </header>
    <v-card
      :loading="cargando"
      :disabled="cargando"
      flat
    >
      <v-card-title>Información general</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-select
              v-model="proveedor.tipo_pro"
              :error-messages="errors.tipo_pro"
              :items="[{text:'Persona física',value:'fisica'},{text:'Persona moral',value:'moral'}]"
              label="Tipo*"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              v-model="proveedor.razon_social_pro"
              :error-messages="errors.razon_social_pro"
              label="Razón social*"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              v-model="proveedor.nombre_comercial_pro"
              :error-messages="errors.nombre_comercial_pro"
              label="Nombre comercial"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              v-model="proveedor.rfc_pro"
              :error-messages="errors.rfc_pro"
              label="R.F.C.*"
              filled
              v-mask="'NNNNNNNNNNNNN'"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              v-model="proveedor.cp_pro"
              :error-messages="errors.cp_pro"
              label="Código postal*"
              filled
              v-mask="'######'"
              pattern="\d*"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="_updateProveedor"
          color="primary"
        >Actualizar información</v-btn>
      </v-card-actions>
      <v-divider />
      <v-card-title>Documentación requerida</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-autocomplete
              label="Banco"
              v-model="proveedor.banco_pro"
              filled
              :items="bancos"
              item-text="banco"
              item-value="id"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              label="Núm. cuenta bancaria"
              v-model="proveedor.banco_cuenta_pro"
              v-mask="'###########'"
              filled
              pattern="\d*"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              label="CLABE interbancaria"
              v-model="proveedor.banco_clave_pro"
              v-mask="'##################'"
              pattern="\d*"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <file-upload
              v-if="!proveedor.banco_caratula_pro"
              label="Carátula bancaria"
              :path="filePath"
              @file="_handleFileUploaded($event,'banco_caratula_pro')"
            />
            <div v-else>
              <v-btn
                :href="$files+proveedor.banco_caratula_pro"
                target="_blank"
                color="primary"
                depressed
                block
                outlined
              >
                <v-icon>download</v-icon>Carátula bancaria
              </v-btn>
              <br /><br />
              <v-btn
                small
                text
                @click="proveedor.banco_caratula_pro=null"
                block
              >Actualizar carátula bancaria</v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <file-upload
              v-if="!proveedor.constancia_fiscal_pro"
              label="Constancia de situación fiscal"
              :path="filePath"
              @file="_handleFileUploaded($event,'constancia_fiscal_pro')"
            />
            <div v-else>
              <v-btn
                :href="$files+proveedor.constancia_fiscal_pro"
                target="_blank"
                color="primary"
                depressed
                block
                outlined
              >
                <v-icon>download</v-icon>Carátula bancaria
              </v-btn>
              <br /><br />
              <v-btn
                small
                text
                @click="proveedor.constancia_fiscal_pro=null"
                block
              >Actualizar constancia fiscal</v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <file-upload
              v-if="!proveedor.sat_no_adeudo_pro"
              label="Opinion de cumplimiento de obligaciones fiscales"
              :path="filePath"
              hint='La "Opinión de cumplimiento de obligaciones fiscales" debe tener una antiguedad menor a 2 meses y actualizarse frecuentemente'
              @file="_handleFileUploaded($event,'sat_no_adeudo_pro')"
            />
            <div v-else>
              <v-btn
                :href="$files+proveedor.sat_no_adeudo_pro"
                target="_blank"
                color="primary"
                depressed
                block
                outlined
              >
                <v-icon>download</v-icon>Opinion de cumplimiento de obligaciones fiscales
              </v-btn>
              <br /><br />
              <v-btn
                small
                text
                @click="proveedor.sat_no_adeudo_pro=null"
                block
                class="mb8"
              >Actualizar Opinion de cumplimiento de obligaciones fiscales</v-btn>
              Debe tener una antiguedad menor a 1 mes y actualizarse cada 2 meses
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="_updateProveedor"
          color="primary"
        >Actualizar información</v-btn>
      </v-card-actions>
    </v-card>

  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
import ListItem from "@/components/lists/ListItem.vue";
import FileUpload from "@/components/forms/files/FileUpload.vue";
export default {
  name: "ProveedorForm",
  mixins: [standard, forms, dates],
  components: {
    HeaderTitle,
    ListItem,
    FileUpload,
  },
  data() {
    return {
      name: "",
      cargando: true,
      proveedor: {
        id_pro: null,
        nombre_comercial_pro: null,
        tipo_pro: null,
        razon_social_pro: null,
        rfc_pro: null,
        cp_pro: null,
        regimen_fiscal_pro: null,
        constancia_fiscal_pro: null,
        banco_pro: null,
        banco_cuenta_pro: null,
        banco_clave_pro: null,
        banco_caratula_pro: null,
        sat_no_adeudo_pro: null,
        sat_fecha_pro: null,
        id: null,
      },
      errors: {
        tipo_pro: null,
        razon_social_pro: null,
        nombre_comercial_pro: null,
        rfc_pro: null,
        cp_pro: null,
      },
      bancos: [],
      filePath: "",
    };
  },
  methods: {
    _getProveedor(res) {
      this._getThings("proveedor", "proveedor");
    },
    _getBancos() {
      this._getThings("bancos", "bancos");
    },
    _handleFileUploaded(file, field) {
      this.proveedor[field] = file;
      this._updateProveedor();
    },
    _updateProveedor() {
      this._updateAll(
        "proveedor/" + this.proveedor.id + "/",
        {
          proveedor: this.proveedor,
        },
        "msg",
        "_handleResponseProveedor"
      );
    },
    _handleResponseProveedor(res) {
      try {
        if (res && "msg" in res) this.$emit("msg", res.msg);
      } catch (e) {}
    },
  },
  created() {
    this._getProveedor();
    this._getBancos();
  },
  watch: {
    proveedor(a) {
      const { id } = a;
      if (id) this.filePath = "proveedores/" + id + "/";
    },
  },
};
</script>
<style lang="scss">
</style>